@import 'theme/breakpoints.scss';

.cookies {
    position: relative;

    &__wrapper {
        display: flex;
        flex-direction: column;
        padding: calc(var(--spacer) * 1.5) 0;

        @media screen and (min-width: $md) {
            flex-direction: row;
            justify-content: space-between;
            align-items: center;
        }
    }

    &__actions {
        margin-top: var(--spacer);
        @media screen and (min-width: $md) {
            margin-top: 0;
            padding-left: calc(var(--spacer) * 3);
        }
    }

    .link {
        color: var(--body-color);
        font-weight: bold;
        vertical-align: baseline;
    }

    .modal {
        &.fade .modal-dialog {
            transform: translate(0, 100%);
        }
        &.show .modal-dialog {
            transform: translate(0, 0);
        }
    }

    .modal-dialog {
        position: absolute;
        left: 0;
        right: 0;
        bottom: 0;
        width: 100%;
        max-width: 100% !important;
        margin: 0 !important;

        &:before,
        &:after {
            content: none !important;
        }
    }

    .modal-content {
        width: 100%;
        border-radius: 0;
    }
}
