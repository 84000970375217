@import 'theme/breakpoints.scss';

.the-header {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    min-height: var(--header-height);
    padding: calc(var(--spacer) * 1.25) 0 0 0;
    background-color: var(--white);
    box-shadow: var(--shadow);
    z-index: 10;

    &__wrapper {
        display: flex;
        align-items: center;
        width: 90%;
        max-width: 1140px;
        height: 100%;
        margin: 0 auto;
    }

    &__path {
        position: absolute;
        bottom: -1px;

        @media (min-width: 400px) {
            display: none;
        }
    }

    &.animated {
        background-color: transparent;
        box-shadow: none;
        transition: var(--transition);

        @media (max-width: $sm) {
            background: var(--gradient-1);
        }

        &.active {
            box-shadow: var(--shadow);
        }
    }

    .brand {
        padding-bottom: calc(var(--spacer) * 0.75);

        @media (min-width: $md) {
            padding-bottom: calc(var(--spacer) * 1.25);
        }
    }

    .logo {
        width: 170px;

        @media (max-width: $lg) {
            width: 53px;
        }
    }
}
