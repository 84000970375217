@import 'theme/breakpoints.scss';

.nav-signin {
    position: absolute;
    right: calc(5% + #{calc(var(--spacer) * 4)});
    color: var(--body-color);
    transform: translateY(-50%);
    transition: var(--transition);

    &.invert {
        color: var(--white);
        text-decoration-color: var(--white) !important;

        .icon,
        span {
            color: var(--white);
        }

        &:hover {
            .icon,
            span {
                color: var(--white);
            }
        }
    }
    &:hover {
        color: var(--primary);

        .icon,
        span {
            color: var(--primary);
        }
    }
}
