@import 'theme/breakpoints.scss';

.the-footer {
    padding-top: calc(var(--spacer) * 5);
    background: var(--white);

    .subfooter {
        &::before {
            content: '';
            display: block;
            width: 100%;
            height: 2px;
            margin: 0 0 calc(var(--spacer) * 2.5) 0;
            background: var(--gradient-reverse);
        }

        &__wrapper {
            display: flex;
            flex-direction: column;

            @media screen and (min-width: $md) {
                flex-direction: row;
                flex-wrap: wrap;
            }

            .section {
                width: 100%;

                @media screen and (min-width: $md) {
                    width: 50%;
                }

                @media screen and (min-width: $lg) {
                    width: 23.5%;
                }

                &--rrss {
                    @media screen and (min-width: $lg) {
                        width: 29.5%;
                    }
                }
            }
        }

        .contact-bar {
            margin: calc(var(--spacer) * 2) 0;

            .icon {
                margin-right: calc(var(--spacer) * 0.5);
            }

            .phone {
                margin: calc(var(--spacer) * 0.5) 0;
            }
        }

        .menu {
            margin: var(--spacer) 0 calc(var(--spacer) * 2) 0;
            padding: 0;
            list-style: none;

            li {
                margin-bottom: calc(var(--spacer) * 0.25);

                a {
                    color: var(--body-color);
                    font-size: 12px;
                }

                &:before {
                    content: '';
                    display: inline-block;
                    width: 6px;
                    height: 6px;
                    margin-right: calc(var(--spacer) * 0.5);
                    border-bottom: 1px solid var(--body-color);
                    border-right: 1px solid var(--body-color);
                    transform: rotate(-45deg) translate(1px, 0);
                }
            }
        }

        .rrss {
            padding: var(--spacer) 0 calc(var(--spacer) * 3) 0;
            text-align: center;

            @media screen and (min-width: $md) {
                padding: 0;
                text-align: right;
            }

            li {
                display: inline-block;
                & + li {
                    margin: 0 0 0 var(--spacer);
                }
            }
        }
    }

    .copyright-bar {
        padding: calc(var(--spacer) * 1.25) 0;
        background: var(--gradient-1);
    }
}
