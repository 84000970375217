@import 'theme/breakpoints.scss';

@mixin center($width) {
    width: $width;
    margin-left: $width / 2 * -1;
}

.nav-desktop {
    display: flex;
    height: 0;
    opacity: 0;
    transition: opacity var(--animation-time) ease-in-out;

    @media screen and (min-width: 1100px) {
        justify-content: space-around;
        align-items: flex-end;
        width: calc(100% - 155px);
        height: auto;
        opacity: 1;
    }

    ul {
        margin: 0;
        padding: 0;
        list-style: none;
    }

    &>ul {
        display: none;

        @media screen and (min-width: $lg) {
            display: flex;
        }

        >li {
            padding-bottom: calc(var(--spacer) * 2);

            &.menu-item {
                display: block;
            }

            &.one-column {
                position: relative;

                .submenu {
                    width: 380px;
                    left: 50%;
                    transform: translateX(-50%);
                }
            }

            &:hover {
                &>.submenu {
                    display: flex;
                    visibility: initial;
                    opacity: 1;
                }

                &>a>span:before {
                    width: 100%;
                }
            }

            &>a {
                padding: calc(var(--spacer) * 0.5) var(--spacer);

                &>span {
                    position: relative;

                    &:before {
                        content: '';
                        width: 0%;
                        height: 2px;
                        background-color: var(--white);
                        position: absolute;
                        left: 50%;
                        transform: translateX(-50%);
                        bottom: calc(var(--spacer) * -0.25);
                        transition: width 0.5s ease-in-out;
                    }

                    &.black:before {
                        background-color: var(--brand);
                    }
                }
            }
        }

        a {
            span {
                transition: var(--transition);
            }

            &:hover {
                &.invert {

                    span,
                    .icon {
                        color: var(--white);
                    }
                }
            }
        }
    }

    &__cta {
        display: none;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        margin: 0 auto;
        padding-bottom: calc(var(--spacer) * 1.25);

        @media screen and (min-width: $lg) {
            display: flex;
        }

        button+button {
            margin-top: var(--spacer);
        }

        @media screen and (min-width: $lg) {
            flex-direction: row;
            margin: 0 0 0 var(--spacer);

            button+button {
                margin-top: 0;
                margin-left: var(--spacer);
            }
        }

        @media screen and (min-width: $xl) {
            margin: 0 0 0 calc(var(--spacer) * 3);

            button+button {
                margin-left: calc(var(--spacer) * 2);
            }
        }

        &--placeholder {
            min-width: 250px;
            min-height: 68px;

            @media screen and (min-width: $xl) {
                min-width: 350px;
            }
        }
    }

    .menu {
        position: relative;
    }

    .submenu {
        position: absolute;
        top: calc(100% + #{8px});
        left: 0%;
        flex-direction: column;
        background: var(--body-bg);
        border-radius: var(--border-radius);
        box-shadow: var(--shadow);
        display: none; // NOTE: Disabled animation
        opacity: 0;
        visibility: hidden;
        width: 760px;
        transition: var(--transition);

        &:before {
            content: '';
            position: absolute;
            top: -18px;
            left: 50%;
            transform: translateX(-50%);
            width: 0;
            height: 0;
            margin-left: -3px;
            border-style: solid;
            border-width: 0 16px 18px 16px;
            border-color: transparent transparent var(--white) transparent;
            z-index: 1;
            transition: height 0.35s ease-in-out;
            -webkit-filter: drop-shadow(1px 1px 1px rgba(0, 0, 0, 0.3));
            filter: drop-shadow(0px -1px 1px rgba(0, 0, 0, 0.3));
        }

        &:after {
            content: '';
            position: absolute;
            top: -8px;
            left: 0;
            width: 100%;
            height: 8px;
        }

        &>ul {
            display: flex;
            gap: calc(var(--spacer) * 2.5);
            width: 100%;
            padding: calc(var(--spacer) * 2.5) calc(var(--spacer) * 2.5) calc(var(--spacer) * 1.5);
        }

        &-accountsAndCards {
            &:before {
                left: calc(var(--spacer) * 5.75);
            }
        }

        &-investment {
            &:before {
                left: calc(var(--spacer) * 14.5);
            }
        }

        &-lendings {
            &:before {
                left: calc(var(--spacer) * 28.5);
            }
        }

        &__featured {
            position: relative;

            &:before {
                content: '';
                position: absolute;
                top: 0;
                left: 0;
                width: 100%;
                height: 2px;
                background: var(--gradient-reverse);
            }
        }

        &-item {
            &>ul {
                margin-top: calc(var(--spacer) * 2);
            }

            &__title {
                margin-bottom: 0;

                .icon {
                    margin-left: calc(var(--spacer) * 0.5);
                }

                position: relative;

                &:after {
                    width: calc(var(--spacer) * 1.5);
                    height: 2px;
                    background-color: var(--brand);
                    content: '';
                    display: block;
                }
            }
        }

        &-link {
            p {
                transition: color 0.2s ease-in-out;
            }

            &:hover p {
                color: var(--brand);
            }

            &+.submenu-link {
                margin-top: calc(var(--spacer) * 2);
            }

            .icon {
                margin-right: 10px;
            }

            .section-title {
                margin-bottom: 0;
            }
        }
    }

    &__button {
        span {
            white-space: nowrap;
        }
    }
}