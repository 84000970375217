@import 'theme/breakpoints.scss';

.nav-button {
    position: fixed;
    right: 5%;
    display: none;
    width: 24px;
    height: 18px;
    padding: 0;
    border: none;
    background-color: transparent;
    appearance: none;
    outline: 0;
    line-height: 0;
    cursor: pointer;
    transform: translateY(-50%);

    &:focus,
    &:active {
        outline: none;
    }

    @media screen and (max-width: $lg) {
        display: block;
    }

    span,
    &:before,
    &:after {
        background-color: var(--dark);
        border-radius: 8px;
    }

    span {
        display: inline-block;
        width: 100%;
        height: 2px;
        margin-bottom: 1px;
        font-size: 16px;
        transform: translateY(-50%);
        transition: width var(--animation-time) ease-in-out var(--animation-time);
    }

    &:before {
        content: '';
        width: 100%;
        height: 2px;
        position: absolute;
        top: 0;
        left: 0;
        transform-origin: top left;
        transition: transform var(--animation-time) ease-in-out var(--animation-time),
            top var(--animation-time) ease-in-out;
    }
    &:after {
        content: '';
        width: 100%;
        height: 2px;
        position: absolute;
        bottom: 0;
        left: 0;
        transform-origin: bottom left;
        transition: transform var(--animation-time) ease-in-out var(--animation-time),
            bottom var(--animation-time) ease-in-out;
    }
    &.invert {
        span,
        &:before,
        &:after {
            background-color: var(--white);
        }
    }

    &.active {
        span {
            width: 0;
            transition: width var(--animation-time) ease-in-out;
        }
        &:before {
            transform: rotate(45deg);
            transition: transform var(--animation-time) ease-in-out var(--animation-time)/2;
        }
        &:after {
            transform: rotate(-45deg);
            transition: transform var(--animation-time) ease-in-out var(--animation-time)/2;
        }
        span,
        &:before,
        &:after {
            background-color: var(--dark);
        }
    }
}
