@import './variables.scss';
@import './breakpoints.scss';

html,
body {
    font-size: 14px;

    @media screen and (min-width: $sm) {
        font-size: 16px;
    }
}

// Custom properties
:root {
    --css-header-height: 64px;

    @media screen and (min-width: $md) {
        --css-header-height: 85px;
    }
}

// Global styles
.screen__wrapper {
    width: 90%;
    margin: 0 auto;
    max-width: 1140px;
}