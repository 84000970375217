@import 'theme/breakpoints.scss';

$footer-height: 80px;

.nav-mobile {
    $self: &;
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    height: 100%;
    background-color: var(--white);
    opacity: 0;
    visibility: hidden;
    transition: var(--transition);

    @media screen and (min-width: $lg) {
        display: none;
    }

    &__wrapper {
        height: 100%;
    }

    &__section {
        position: absolute;
        top: 0;
        left: 110%;
        width: 100%;
        height: calc(100% - #{$footer-height});
        padding: 44px 0 calc(var(--spacer) * 2) 0;
        background: var(--white);
        box-shadow: -6px 0 6px -6px rgba(var(--black-rgb), 0.3);
        transition: left 350ms ease-in-out;
        overflow-y: auto;

        &:first-child,
        &.selected {
            left: 0;
        }

        &-header {
            position: fixed;
            top: 0;
            padding: calc(var(--spacer) * 1.25) 5% var(--spacer) 5%;
            width: 100%;
            background: var(--white);
        }
    }

    &.isAuth {
        #{$self}__section {
            top: var(--header-auth-height);
        }
    }

    .list {
        margin: calc(var(--spacer) * 1.5) 0;
        padding: 0 5%;

        &--featured {
            position: relative;
            padding: calc(var(--spacer) * 1.5) 5% 0 5%;

            &:before {
                content: '';
                position: absolute;
                top: 0;
                left: 0;
                width: 100%;
                height: 2px;
                background: var(--gradient-reverse);
            }
        }

        &-item {
            display: block;
        }

        &-item + .list-item {
            margin-top: calc(var(--spacer) * 1.5);
        }
    }

    .submenu {
        margin-top: var(--spacer);
        padding-left: calc(var(--spacer) * 0.5);

        &-link + .submenu-link {
            margin-top: var(--spacer);
        }
    }

    &__footer {
        position: absolute;
        bottom: 0;
        left: 0;
        right: 0;
        width: 100%;
        height: $footer-height;
        padding: var(--spacer) calc(var(--spacer) * 1.5);
        box-shadow: var(--shadow);
        background: var(--white);

        .button + .button {
            margin-left: calc(var(--spacer) * 1.25);
        }
    }
}
